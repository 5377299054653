// @import "../../theme/variables/variables.scss";
// @import "../../theme/helpers/typography";
// @import "../../theme/helpers/functions";
// @import "~susy";
@import "../../theme/index.module.scss";
// @import "../../theme/mixins.scss";
// @import "~typi";

.Slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: white;

  :global {
    .slick-initialized .slick-slider {
      position: relative;
    }

    // .slick-list {
    //   padding: 0 20px 0 0;
    // }

    .slick-track {
      display: flex;
      height: 336px;

      @include bp($medium) {
        height: 50vh;
      }

      @include bp($larger) {
        height: 85vh;
      }
    }

    .slick-active {
      > div {
        display: block !important;

        a {
          display: block !important;
          height: 100%;
        }
      }
    }

    .slick-cloned {
      > div {
        display: none;
      }
    }

    .slick-slide {
      height: 100%;
      > div {
        height: 100%;
        a {
          display: none;
        }
      }


    }

    .slick-slide img {
      margin: auto;
    }

    .slick-dots {
      display: flex !important;
      list-style: none;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0;
      margin: 1.45rem auto;
      right: 0;
      justify-content: center;

      .slick-active {
        background: #E50100 !important;
      }

      li {
        width: 1rem;
        height: 1rem;
        background: #CC8F8F;
        border-radius: 100%;
        margin: 0 .3em;

        @include max-bp($large) {
          width: .85rem;
          height: .85rem;
        }

        button {
          opacity: 0;
        }
      }
    }
  }
}

.Container {
  position: relative;
  width: 100% !important;
  height: 100%;
}

.Content {
  z-index: 2;
  position: absolute;
  top: -4rem;
  flex-direction: column;
  right: 0;
  width: 100%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include bp($larger) {
    max-width: 790px;
    top: -2rem;
  }

}

.Category {
  width: 68%;
  margin: 0 auto 1em;
  display: flex;

  div {
    background: white;
    padding: .2em .5em;
    text-transform: uppercase;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  p:first-child {
    color: #E60201;
    position: relative;
    font-weight: 700;

    &::after {
      content: ' ';
      width: 6px;
      height: 6px;
      background: #E60201;
      position: absolute;
      border-radius: 100%;
      margin: .4em .6em;

      @include bp($medium) {
        margin: .5em .7em;
      }
    }
  }

  p {
    margin: 0;
    color: #E60201;
    font-size: 12px;

    @include bp($medium) {
      font-size: 18px;
    }
  }

  p:nth-child(2) {
    margin-left: 1.6em;

    @include bp($medium) {
      margin-left: 1.8em;
    }
  }
}

.title {
  position: relative;
  width: 68%;
  margin: 0 auto;
  color: white;
  text-transform: capitalize;

  h1 {
    font-size: 35px;
    line-height: 3.2rem;
    margin: 0;

    @include max-bp($small) {
      font-size: 25px;
      line-height: 2.5rem;
      margin: 0;
    }

    @include bp($larger) {
      font-size: 60px;
      line-height: 6.2rem;
    }

    span {
      -webkit-box-decoration-break: clone;
      -moz-box-decoration-break: clone;
      box-decoration-break: clone;
      padding: .01em .1em;
      background: #E60201;
    }
  }

  @include bp($medium) {
    padding: .01em .1em;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    -moz-box-decoration-break: clone;
    box-decoration-break: clone;
  }



  @include bp($larger) {
    max-width: 550px;
  }
}

.button {
  width: 68%;
  margin: .75em auto 0;
  background: transparent;

  @include bp($medium) {
    font-size: 18px;

    &:hover {
      img {
        margin-left: .8em;
        transition: margin-left .3s;
      }
    }
  }

  a {
    color: #E60201;
    display: flex;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;

    img {
      margin-left: .5em;
    }
  }
}


